export const LinkExpired = () => {
  return (
    <div>
      <h1 className="text-xl">Page/Link Expired</h1>
      <p className="text-gray-600">
        Sorry, the page/link that you're looking for has expired.
      </p>
    </div>
  );
};
