import "./App.css";
import logo from "./logo.png";

import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  // useParams,
} from "react-router-dom";

import { FileUpload } from "./components";
import { LinkExpired, ErrorNotFound } from "./errors";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ErrorNotFound />} />
          <Route path="file-upload/:inviteId" element={<FileUpload />} />
          <Route path="expired" element={<LinkExpired />} />
          <Route path="error" element={<ErrorNotFound />} />
          <Route path="*" element={<ErrorNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout() {
  return (
    <div className="App">
      <div className="flex justify-center">
        <h1>
          <img src={logo} width="100" alt="Hoag Logo" />
        </h1>
      </div>
      <Outlet />
    </div>
  );
}

export default App;
